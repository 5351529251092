import React, { useState } from "react";
import Layout from "../../layout/Layout";
import MetaData from "../other/MetaData";

const PartnersComponent = () => {
  const [showContactInfo, setShowContactInfo] = useState(false);

  const handleButtonClick = async () => {
    try {
      setShowContactInfo(true);

      if (!showContactInfo) {
        const response = await fetch(
          "https://www.api.affiliatedrefer.com/record/partnerbtn-clicked",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          console.log("Button click recorded successfully!");
        } else {
          console.error("Failed to record button click:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error occurred while recording button click:", error);
    }
  };

  return (
    <Layout>
      <MetaData title={"Affiliated Refer"} />
      <section className="text-center py-4 bg-light">
        <div className="container">
          <h1
            className="text-primary mb-3"
            style={{ fontSize: "18px", fontWeight: "bold" }}
          >
            Partnership Opportunities
          </h1>
          <p className="mb-4 " style={{ fontSize: "16px" }}>
            We launched very recently and currently have no partners. Click the
            button below to contact us for potential partnerships.
          </p>
          <button
            className="btn btn-primary btn-lg"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleButtonClick}
          >
            Potential Partnership
          </button>
          {showContactInfo && (
            <div className="mt-3">
              <p className="text-muted" style={{ fontSize: "16px" }}>
                <span style={{ fontWeight: "bold" }}>Email us at:</span>{" "}
                <a
                  href="mailto:affiliatedrefer@gmail.com"
                  className="text-primary"
                >
                  affiliatedrefer@gmail.com
                </a>{" "}
                or{" "}
                <a
                  href="mailto:info@affiliatedrefer.com"
                  className="text-primary"
                >
                  info@affiliatedrefer.com
                </a>
              </p>
            </div>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default PartnersComponent;
