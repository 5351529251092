import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import logo from "../../assets/whitehomenav.png";
import AppNotifications from "../other/AppNotifications";
import Dropdown from "./Dropdown";
import { font } from "@adminjs/design-system";
import { GoQuestion } from "react-icons/go";
import Tooltip from "@mui/material/Tooltip";
const Navbar = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.userReducer.user);
  return (
    <div className="nav-container">
      <div className="left">
        <div
          className="nav-icon"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/home");
          }}
        >
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="right">
        <p style={{ fontSize: '24px', color: 'white' }}>{userDetails.name}</p>
        <div
          className="nav-link"
          onClick={() => {
            window.scrollTo(0, 0);
            navigate("/create-post");
          }}
        >
          <Tooltip title="Enlist Program" arrow>
      <AddIcon style={{ cursor: "pointer" }} />
    </Tooltip>
        </div>
        <AppNotifications />

        {/* DropDown */}
        <Dropdown />
      </div>
    </div>
  );
};

export default Navbar;
