import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import MetaData from "../components/other/MetaData";
import { useLocation, useParams } from "react-router-dom";
import { createPost } from "../redux/actions/postsAction";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const PaymentEnlistLoader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const socket = useSelector((state) => state.socketReducer.socket);
  const [status, setStatus] = useState("loading");
  const [error, setError] = useState(false);

  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const id = urlParams.get("transactionId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.api.affiliatedrefer.com/paymentgateway/api/verifyTransaction/${id}`
        );
        const data3 = await response.json();

        if (data3.payment.Status === "Complete") {
          let data = data3.payment.EnlistData;

          let user = data3.payment.user;
          dispatch(
            createPost({
              data,
              navigate,
              socket,
              receiverId: user,
            })
          );
        } else {
          setStatus("error");
          setError(true);
        }
      } catch (err) {
        setStatus("error");
        setError(true);
      }
    };

    fetchData();
  }, []);

  if (status === "loading") {
    return (
      <Layout>
        <MetaData title={"Affiliated Refer"} />
        <div style={styles.container}>
          <div style={styles.loader}></div>
          <p className="mt-5">Saving enlist, please wait...</p>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <MetaData title={"Affiliated Refer"} />
        <div style={styles.container}>
          <p className="mt-5" style={{ color: "red" }}>
            Error: Unable to find transaction or complete the enlistment
            process.
          </p>
          <button
            onClick={() =>
              (window.location.href =
                "https://www.affiliatedrefer.com/create-post")
            }
            style={styles.retryButton}
          >
            Enlist Again
          </button>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <MetaData title={"Affiliated Refer"} />
      <div style={styles.container}>
        <p className="mt-5">Enlistment process completed successfully!</p>
      </div>
    </Layout>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    fontFamily: "Arial, sans-serif",
  },
  loader: {
    width: "50px",
    height: "50px",
    border: "5px solid #f3f3f3",
    borderTop: "5px solid #3498db",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  retryButton: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#3498db",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default PaymentEnlistLoader;
