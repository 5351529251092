import React from "react";
import Layout from "../layout/Layout";
import MetaData from "../components/other/MetaData";

const PaymentCancelEnlist = () => {
  return (
    <Layout>
      <MetaData title={"Affiliated Refer"} />
      <div style={styles.container}>
        <h1 style={styles.errorText}>Payment Failed</h1>
        <p>Something went wrong. Please try again.</p>
      </div>
    </Layout>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  errorText: {
    color: "#e74c3c",
    fontSize: "24px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#3498db",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    marginTop: "15px",
  },
};

export default PaymentCancelEnlist;
